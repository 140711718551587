(function () {
  'use strict';

  angular
    .module('ss.admin.services')
    .factory('languagesAdminService', languagesAdminService);

  languagesAdminService.$inject = ['dataService', 'notificationService', 'stateService'];

  function languagesAdminService(dataService, notificationService, stateService) {
    const endPoint = 'admin/languages/';

    const service = {
      getAll: getAll,
      getSupportEngines: getSupportEngines,
      updateSpeechApi: updateSpeechApi
    };
    return service;

    ////////////////
    function getAll() {
      return dataService.get(endPoint);
    }

    function getSupportEngines(langRegionCode) {
      return dataService.get(endPoint, `check-service-support?langCode=${langRegionCode}`);
    }

    function updateSpeechApi(langRegionCode, regionId, defaultApi, backupApi) {
      return dataService.patch(endPoint, {
        langCode: langRegionCode,
        regionId: regionId,
        newSpeechServiceCode: defaultApi,
        newBackupSpeechServiceCode: backupApi
      }, `update-speech-service`);
    }
  }
})();
