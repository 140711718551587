(function () {
  "use strict";

  angular.module("ss.client.pages").component("accountSettingsPageComponent", {
    controller: AccountSettingsPageComponent,
    bindings: {},
    templateUrl:
      "app/client/pages/account-settings-page/account-settings-page.component.html",
  });

  AccountSettingsPageComponent.$inject = [
    "$timeout",
    "sharedService",
    "$location",
    "$anchorScroll",
    "SS_CONSTANT",
    "authService",
    "usersService",
    "notificationService",
    "$uibModal",
    "subscriptionService",
    "boxAuthenticationService",
    "$window",
    "stateService",
    "projectService",
  ];

  function AccountSettingsPageComponent(
    $timeout,
    sharedService,
    $location,
    $anchorScroll,
    SS_CONSTANT,
    authService,
    usersService,
    notificationService,
    $uibModal,
    subscriptionService,
    boxAuthenticationService,
    $window,
    stateService,
    projectService
  ) {
    const me = this;
    const gapInMilliseconds = 3600000;
    me.$onInit = onInit;
    me.user = sharedService.getUser();
    me.goTo = goTo;
    me.formatDuration = formatDuration;
    me.getInviteCode = getInviteCode;
    me.getExpDate = getExpDate;
    me.updateSubscribeFlow = updateSubscribeFlow;
    me.isTeamOwner = isTeamOwner;
    me.isPlanActive = isPlanActive;
    me.cancelUserPlan = cancelUserPlan;
    me.undoDontCancelUserPlan = undoDontCancelUserPlan;
    me.boxAuthorize = boxAuthorize;
    me.boxDeAuthorize = boxDeAuthorize;
    me.toggleCreationPassword = toggleCreationPassword;
    me.createPassword = createPassword;
    me.changePassword = changePassword;
    me.cleanPasswordCreation = cleanPasswordCreation;
    me.deleteAccount = deleteAccount;
    me.isUserUsingGoogleAuth = isUserUsingGoogleAuth;
    me.connectGoogleAccount = connectGoogleAccount;
    me.isUserUsingEmailAuthentication = isUserUsingEmailAuthentication;
    me.isUserSubscriptionActive = isUserSubscriptionActive;
    me.planActionText = planActionText;
    me.downloadInvoiceMessage = downloadInvoiceMessage;
    me.onSave = onSave;
    me.onEdit = onEdit;
    me.downloadInvoice = downloadInvoice;
    me.openStartDate = openStartDate;
    me.openEndDate = openEndDate;
    me.validateInvoiceDate = validateInvoiceDate;
    me.openAddCreditModal = openAddCreditModal;
    me.subscribeToPlan = subscribeToPlan;
    me.updateUserFullNameAndAvatar = updateUserFullNameAndAvatar;
    me.updateProfilePic = updateProfilePic;
    me.removeProfilePic = removeProfilePic;
    me.hideRemoveProfilePic = hideRemoveProfilePic;
    me.changeUserEmail = changeUserEmail;
    me.selectFolderInvoices = selectFolderInvoices;
    me.invoiceFormDisabled = true;
    me.invoiceDateError = false;
    me.menu = [
      {
        id: "profile",
        title: "Profile",
      },
      {
        id: "loginPreferences",
        title: "Login Preferences",
      },
      {
        id: "inviteLink",
        title: "Invite link / free credit",
      },
      {
        id: "invoices",
        title: "Invoices",
      },
      {
        id: "transactionHistory",
        title: "Transaction History",
      },
      {
        id: "connectedApps",
        title: "Connected Apps",
      },
      {
        id: "closeAccount",
        title: "Close Account",
      },
    ];
    me.popupStartDate = {
      opened: false,
    };
    me.popupEndDate = {
      opened: false,
    };
    me.dateOptions = {
      showWeeks: false,
    };
    const ssPublicProfile = "simon-says-public-profile-pics";

    function onInit() {
      const oneMonthAgo = new Date();
      const oneDayAhead = new Date();
      oneMonthAgo.setDate(oneMonthAgo.getDate() - 28);
      oneDayAhead.setDate(oneDayAhead.getDate() + 1);
      me.dateOptions.maxDate = oneDayAhead;
      me.invoiceStartDate = oneMonthAgo;
      me.invoiceEndDate = new Date();
      me.inviteLink = `${$location.protocol()}://${
        location.host
      }/signup?inviteCode=${me.user.inviteCode}`;
      validateForm();
      me.showOrHideCreationPassword = false;
      me.creationUserInfo = {};
      me.PLANS = SS_CONSTANT.PLANS;
      me.invoice = me.user.contact;
      if (!me.invoice) {
        me.invoiceFormDisabled = false;
        me.invoice = {};
      }
      retrieveTransactions();
      getUserDirectories();
      $anchorScroll.yOffset = 150;
      $timeout(() => $anchorScroll(), 1000);
      fetchSubscriptionDetails();

      $(document).on("scroll", () =>
        $(".target").each((i, item) => {
          if ($(window).scrollTop() >= $(item).position().top - 500) {
            $(".nav.account-settings__nav__aside--list li a").removeClass(
              "selected"
            );
            $(
              `.nav.account-settings__nav__aside--list li a[href='#${$(
                item
              ).attr("id")}']`
            ).addClass("selected");
          }
        })
      );
    }

    function isUserUsingGoogleAuth() {
      return (
        me.user &&
        me.user.identities &&
        me.user.identities.indexOf("google-oauth2") !== -1
      );
    }

    function connectGoogleAccount() {
      authService.loginWithGoogle();
    }

    function isUserUsingEmailAuthentication() {
      return (
        me.user &&
        me.user.identities &&
        me.user.identities.indexOf("auth0") !== -1
      );
    }

    function toggleCreationPassword() {
      me.showOrHideCreationPassword = !me.showOrHideCreationPassword;
    }

    function cleanPasswordCreation() {
      me.creationUserInfo = {};
      me.showOrHideCreationPassword = false;
    }

    function createPassword() {
      usersService
        .addAuth0UsernamePasswordIdentity(me.creationUserInfo.password)
        .then(() => {
          cleanPasswordCreation();
          return reloadUserInformation().then((user) => {
            sharedService.setUser(user);
            me.user = user;
          });
        })
        .then(() => notifySuccessForCreatingPassword())
        .catch((error) => {
          notificationService.errorCatcher(error.data.message)(error);
        });
    }

    function changePassword() {
      authService
        .changePassword(me.user.email)
        .then(() =>
          notificationService.success(
            "An email has been sent with instructions to change your password."
          )
        )
        .catch((reason) =>
          notificationService.errorCatcher(
            "Some error happened at the time to create password."
          )(reason)
        );
    }

    function notifySuccessForCreatingPassword() {
      notificationService.success("Password successfully created.");
    }

    function reloadUserInformation() {
      return authService.renewTokens().then(() => usersService.getUserInfo());
    }

    function undoDontCancelUserPlan() {
      notificationService
        .confirm(
          "Are you sure you want to undo the cancellation of your current subscription?"
        )
        .then(() =>
          subscriptionService
            .undoDontCancelUserPlan()
            .then(() => fetchSubscriptionDetails())
        )
        .catch(() => {});
    }

    function planActionText() {
      return _.isEmpty(me.user.plan.downgradeOptions)
        ? "Cancel Plan"
        : "Downgrade or Cancel Plan";
    }

    function cancelUserPlan() {
      const modalInstance = $uibModal.open({
        animation: true,
        backdrop: "static",
        component: "cancelFlowModal",
      });

      modalInstance.result.then(() => {
        fetchSubscriptionDetails();
        reloadUser();
      });
    }

    function formatDuration(duration) {
      return moment.duration(duration, "minutes")._data;
    }

    function getInviteCode() {
      usersService.getInviteCode().then((response) => {
        me.user.inviteCode = response.code;
        me.user.hasInviteCodeExpired = response.hasExpired;
      });
    }

    function goTo(event, hash) {
      $location.hash(hash);
      $anchorScroll();
    }

    function updateSubscribeFlow(plan) {
      const modalInstance = $uibModal.open({
        animation: true,
        backdrop: "static",
        component: "updatePaymentDetails",
        resolve: {
          planInfo: () => plan,
        },
      });
      modalInstance.result.then(() =>
        notificationService.success(
          "Your card details have been updated.",
          true
        )
      );
    }

    function getExpDate() {
      if (me.user) {
        let expDate = me.user.subscriptionCreditExpireDate;
        if (isTeamOwner() || me.user.team) {
          expDate = me.user.team.subscriptionCreditExpireDate;
        }
        if (expDate === null) {
          return null;
        }
        return new Date(expDate - gapInMilliseconds).getTime();
      }
    }

    function retrieveTransactions() {
      usersService
        .getTransactions()
        .then((response) => (me.transactions = response));
    }

    function getUserDirectories() {
      projectService
        .getUserDirectories()
        .then((res) => (me.userDirectories = res));
    }

    function isTeamOwner() {
      return me.user.team && me.user.email === me.user.team.owner;
    }

    function isPlanActive() {
      return me.user && me.user.plan.planEnum === me.PLANS.NO_SUBSCRIPTION_PLAN
        ? true
        : new Date() <= new Date(getExpDate());
    }

    function isUserSubscriptionActive() {
      return (
        me.subscriptionDetail &&
        !me.subscriptionDetail.cancelAtPeriodEnd &&
        me.subscriptionDetail.status !== "canceled"
      );
    }

    function boxAuthorize() {
      boxAuthenticationService
        .authorize()
        .then((response) => ($window.location.href = response.boxURI));
    }

    function boxDeAuthorize() {
      boxAuthenticationService.deAuthorize();
    }

    function fetchSubscriptionDetails() {
      subscriptionService
        .fetchCurrentUserSubscription()
        .then((response) => (me.subscriptionDetail = response))
        .catch(() => (me.subscriptionDetail = null));
    }

    function reloadUser() {
      usersService.reloadUser().then(() => {
        me.user = sharedService.getUser();
      });
    }

    function deleteAccount() {
      notificationService
        .confirm(
          "Are you sure you want to permanently close your account and delete all its projects/media/transcripts?"
        )
        .then(() =>
          notificationService
            .confirm(
              "Last confirmation as account closure is final, permanent, and irrecoverable: are you really sure you want to CLOSE your account?"
            )
            .then(() =>
              usersService.deleteAccount().then(() => authService.logout())
            )
            .catch(() => {})
        )
        .catch(() => {});
    }

    function onSave() {
      me.invoiceFormDisabled = true;
      usersService.saveUserContact(me.invoice);
      validateForm();
    }

    function onEdit() {
      me.invoiceFormDisabled = false;
      validateForm();
    }

    function downloadInvoiceMessage(message, error, fixedErrorDate) {
      if (!me.invoiceDateError || error || fixedErrorDate) {
        me.downloadInvoiceMessage = message;
        me.invoiceDateError = error;
      }
    }

    function downloadInvoice() {
      const folderIdInvoices =
        me.userDirectories
          .filter((directory) => directory.filterActive)
          .map((directory) => directory.id) || [];
      let paramsFolder = "";
      folderIdInvoices.forEach((value, index) => {
        paramsFolder += `&folderIds[]=${value}`;
      });
      const data = {
        endUtcTime: me.invoiceEndDate,
        starUtcTime: me.invoiceStartDate,
      };
      usersService.exportInvoice(data, paramsFolder);
    }

    function openStartDate() {
      me.popupStartDate.opened = true;
    }

    function openEndDate() {
      me.popupEndDate.opened = true;
    }

    function validateForm(fixedErrorDate) {
      downloadInvoiceMessage(
        me.invoiceFormDisabled
          ? "Download the invoice:"
          : "Please first save your details and then press download again.",
        false,
        fixedErrorDate
      );
    }

    function validateInvoiceDate() {
      if (me.invoiceEndDate < me.invoiceStartDate) {
        downloadInvoiceMessage(
          "Start date cannot be greater than End date.",
          true
        );
      } else if (me.invoiceEndDate > me.dateOptions.maxDate) {
        downloadInvoiceMessage("End date cannot be in the future.", true);
      } else {
        validateForm(true);
      }
    }

    function openAddCreditModal(plan) {
      $uibModal.open({
        animation: true,
        backdrop: "static",
        component: "addCreditModal",
        resolve: {
          planInfo: () => plan,
        },
      });
    }

    function subscribeToPlan(plan) {
      const modalInstance = $uibModal.open({
        animation: true,
        backdrop: "static",
        component: "subscribeModal",
        resolve: {
          planInfo: () => plan,
        },
      });
      modalInstance.result.then((modalResponse) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'event': 'purchase', 'pricingPlan': modalResponse.planName });

        notificationService.success(
          `Yaay! You subscribed to the ${modalResponse.planName} plan. We’re happy we will be seeing you around more often 🚀.`,
          true
        );
      });
    }

    function updateUserFullNameAndAvatar() {
      const payload = {
        removeAvatar: false,
        fullName: me.user.fullName,
      };
      usersService
        .saveUserFullNameAndAvatar(payload, "name")
        .then(() => notificationService.success("Your name has been updated!"));
    }

    function updateProfilePic() {
      const modalInstance = $uibModal.open({
        animation: true,
        backdrop: "static",
        component: "updateProfilePicModal",
      });
      modalInstance.result.then(() => {
        stateService.reload();
        $timeout(
          () =>
            notificationService.success("Your profile pic has been updated!"),
          1500
        );
      });
    }

    function removeProfilePic() {
      usersService.getDefaultAvatars().then((defaultProfilePics) => {
        const list = defaultProfilePics.map((pic) => pic.id);
        _.random(0, list.length - 1, 0);
        const randomId = _.random(0, list.length - 1, 0) + 1;
        const profilePicUrl = defaultProfilePics.filter(
          (pic) => pic.id === randomId
        )[0].url;

        const payload = {
          avatarUrl: profilePicUrl,
        };
        usersService
          .saveUserFullNameAndAvatar(payload)
          .then(() => stateService.reload());
      });
    }

    function hideRemoveProfilePic() {
      return (
        me.user && me.user.avatar && me.user.avatar.includes(ssPublicProfile)
      );
    }

    function changeUserEmail() {
      const modalInstance = $uibModal.open({
        animation: true,
        backdrop: "static",
        component: "changeUserEmailModal",
      });
      modalInstance.result.then(() => {
        notificationService.success(
          "Yaay! Your email address has been changed, you will be logout from the application, please log back in with your new email address!"
        );
        $timeout(() => usersService.logout(me.user), 5000);
      });
    }

    function selectFolderInvoices(folderId) {
      const tempArray = me.userDirectories.slice();
      const findItem = tempArray.find(
        (directory) => directory.id === folderId.id
      );
      if (findItem) {
        findItem.filterActive = !folderId.filterActive;
      }
      me.userDirectories = tempArray;
    }
  }
})();
