(function () {
  'use strict';
  angular
    .module('ss.common.services')
    .factory('webSocketConnectionService', webSocketConnectionService);

  webSocketConnectionService.$inject = ['$interval', '$timeout', 'host', 'notificationService'];

  function webSocketConnectionService($interval, $timeout, host, notificationService) {
    let stompClient = null;
    let retryConnectionTimer;
    const subscribeQueue = {};
    const subscriptions = {};

    function createTopicId(topic, id) {
      return topic + '_' + id;
    }

    function createChannelId() {
      const args = Array.prototype.slice.call(arguments);
      return '/' + args.join('/');
    }

    function subscribeTopic(topic, id, callback) {
      const topicId = createTopicId(topic, id);
      const topicChannelId = createChannelId('topic', topic, id);
      subscriptions[topicId] = stompClient.subscribe(topicChannelId, response => $timeout(() => {
        return callback(JSON.parse(response.body));
      }));
    }

    function unsubscribeTopic(topic, id) {
      const topicId = createTopicId(topic, id);
      if (subscriptions[topicId]) {
        subscriptions[topicId].unsubscribe();
      }
      if (subscribeQueue[topicId]) {
        delete subscribeQueue[topicId];
      }
    }

    function createSocket() {
      const sockJsProtocols = ['xhr-polling'];
      return new SockJS(host + 'simonsocket', null, {
        transports: sockJsProtocols
      });
    }

    function onConnect() {
      console.log("Version: 1.2.0");
      webSocketConnection.connected = true;
      $interval.cancel(retryConnectionTimer);
      if (!_.isEmpty(subscribeQueue)) {
        angular.forEach(subscribeQueue, (func, topicId) => {
          notificationService.log(`re-subscribing to: ${topicId}`);
          func();
        });
      }
    }

    function onDisconnect() {
      webSocketConnection.connected = false;
      webSocketConnection.connecting = false;
      if (webSocketConnection.disconnectCallback) {
        webSocketConnection.disconnectCallback();
      }

      retryConnectionTimer = setInterval(() => {
        if (!webSocketConnection.connected && !webSocketConnection.connecting) {
          let socket = createSocket();
          stompClient = Stomp.over(socket);
          stompClient.debug = null;
          notificationService.log('Trying to reconnect');
          if (webSocketConnection.reconnectTickCallback) {
            webSocketConnection.reconnectTickCallback();
          }
          stompClient.connect({}, () => {
            onConnect();
            if (webSocketConnection.reconnectCallback) {
              webSocketConnection.reconnectCallback();
            }
          });
        }
      }, 5000);
    }

    const webSocketConnection = {
      connected: false,
      connecting: false,
      disconnectCallback: null,
      reconnectCallback: null,
      reconnectTickCallback: null,
      connect: connect,
      subscribe: subscribe,
      unsubscribe: unsubscribe,
      disconnect: disconnect,
      setDisconnectCallback: setDisconnectCallback,
      setReconnectCallback: setReconnectCallback,
      setReconnectTickCallback: setReconnectTickCallback
    }
    webSocketConnection.connect();
    return webSocketConnection;

    /////////////////////////////////

    function connect() {
      if (!webSocketConnection.connecting) {
        webSocketConnection.connecting = true;
        let socket = createSocket();
        stompClient = Stomp.over(socket);
        // IMPORTANT: ENABLE DEBUGGING BY COMMENTING THIS
        stompClient.debug = null;
        stompClient.connect({}, onConnect, onDisconnect);
      }
    }

    function subscribe(topic, id, callback) {
      if (webSocketConnection.connected) {
        subscribeTopic(topic, id, callback);
      }
      if (!subscribeQueue[createTopicId(topic, id)]) {
        subscribeQueue[createTopicId(topic, id)] = () => subscribeTopic(topic, id, callback);
      }
    }

    function unsubscribe(topic, id) {
      if (webSocketConnection.connected) {
        unsubscribeTopic(topic, id);
      }
    }

    function disconnect() {
      if (stompClient !== null) {
        stompClient.disconnect();
        webSocketConnection.connected = false;
        webSocketConnection.connecting = false;
      }
    }

    function setDisconnectCallback(callback) {
      this.disconnectCallback = callback;
    }

    function setReconnectCallback(callback) {
      this.reconnectCallback = callback;
    }

    function setReconnectTickCallback(callback) {
      this.reconnectTickCallback = callback;
    }

  }
})();
