(function () {
  'use strict';

  angular
    .module('ss.client.pages')
    .component('dashboardPageComponent', {
      controller: DashboardPageComponent,
      bindings: {
        user: '<'
      },
      templateUrl: 'app/client/pages/dashboard-page/dashboard-page.component.html'
    });

  DashboardPageComponent.$inject = ['projectService', 'notificationService', '$document', 'sharedProjectsService', '$window',
    'usersService', 'sharedService', 'webSocketProxy', '$timeout', '$uibModal', 'dictionaryService', 'languagesService',
    '$state', 'SS_CONSTANT', 'storageService'
  ];

  function DashboardPageComponent(projectService, notificationService, $document, sharedProjectsService, $window,
    usersService, sharedService, webSocketProxy, $timeout, $uibModal, dictionaryService, languagesService,
    $state, SS_CONSTANT, storageService) {
    const me = this;

    me.$onInit = onInit;
    me.$onDestroy = onDestroy;
    me.createNewProject = createNewProject;
    me.createNewFolder = createNewFolder;
    me.getSharedProjectsWithMe = getSharedProjectsWithMe;
    me.newSyncProject = newSyncProject;
    me.onDeselectProjectsWithMeTab = onDeselectProjectsWithMeTab;
    me.setActiveTab = setActiveTab;
    me.activateMyDictionariesTab = activateMyDictionariesTab;
    me.activateMyProjectsTab = activateMyProjectsTab;
    me.getProjectTag = getProjectTag;
    me.labelFilter = labelFilter;
    me.toggleFolderOutside = toggleFolderOutside;
    me.selectProjects = selectProjects;
    me.showConfirmDeleteDirectory = showConfirmDeleteDirectory;
    me.isShowTrashFolderProject = isShowTrashFolderProject;
    me.isHideTrashFolderProject = isHideTrashFolderProject;
    me.editFolderName = editFolderName;
    me.getSelectedItemsIncluding = getSelectedItemsIncluding;
    me.onDrop = onDrop;
    me.openDictionary = openDictionary;
    me.isDirectoryOpen = isDirectoryOpen;
    me.toggleAllProjectsAction = toggleAllProjectsAction;
    me.isFolderSelected = isFolderSelected;
    me.onProjectDeleted = onProjectDeleted;
    me.createNewDictionary = createNewDictionary;
    me.showConfirmDeleteDictionary = showConfirmDeleteDictionary;
    me.subscribeToPlan = subscribeToPlan;
    me.newUserTag = {};
    me.popoverTags = {};
    me.popoverMedias = {};
    me.directories = [];
    me.languages = [];
    me.directoriesToDisplay = [];
    me.dictionaries = [];
    me.projectsFiltered = [];
    me.receivedProjects = false;
    me.toggleAllProjects = false;
    me.showAdditionalButtons = false;
    me.activeIndexTab = 0;
    me.permissionOfProjectMap = [];
    me.dictionariesCountLabel = '';
    me.dictionariesSupported = false;
    me.tabsName = {
      myProjects: 'All Projects',
      sharedWithMe: 'Shared with me',
      sharedWithOthers: 'Shared with others',
      myDictionaries: 'My Dictionaries'
    };
    me.dashboardTabs = [
      {
        url: 'my-projects',
        label: me.tabsName.myProjects
      },
      {
        url: 'shared-with-me',
        label: me.tabsName.sharedWithMe
      },
      {
        url: 'shared-with-others',
        label: me.tabsName.sharedWithOthers
      },
      {
        url: 'my-dictionaries',
        label: me.tabsName.myDictionaries
      }
    ];

    let folderName = '';
    let selectedProjects = [];
    let moveType = null;
    let selectedProjectId = null;
    const projectsMoveActionTypes = {
      mainToFolder: 'MainToFolder',
      folderToFolder: 'FolderToFolder',
      folderToMain: 'FolderToMain',
    };

    const user = sharedService.getUser();

    function onInit() {
      const dashboardTab = me.dashboardTabs.find(dt => dt.url === $state.params.activeTab);
      setActiveTab(dashboardTab || me.dashboardTabs[0]);
      me.isMyProjects = false;
      me.isSharedWithMe = false;

      subscribeToEvents();
      retrieveProjects();
      retriveDirectories();
      retrieveUserTags();
      retrieveUserProjectTags();
      retrieveDictionaries();
      retrieveLanguages();
      getSharedProjectsWithMe();
    }

    function labelFilter(project) {
      if (!angular.isArray(me.userTags)) {
        return true;
      }
      const tagFilters = me.userTags.filter(userTag => userTag.filterActive);
      if (tagFilters && tagFilters.length) {
        const projectTag = getProjectTag(project.id)
        if (!projectTag) {
          return false;
        }
        return tagFilters.find(tag => tag.id === projectTag.id);
      }
      return true;
    }

    function retrieveUserTags() {
      usersService.getUserTags().then(response => me.userTags = response);
    }

    function retrieveUserProjectTags() {
      usersService.getUserProjectTags().then(response => me.userProjectTags = response);
    }

    function getProjectTag(projectId) {
      if (angular.isArray(me.userProjectTags)) {
        const userProjectTag = me.userProjectTags.find(userProjectTag => userProjectTag.projectId === projectId);
        if (userProjectTag) {
          if (me.userTags) {
            return me.userTags.find(userTag => userTag.id === userProjectTag.tagId);
          }
        }
      }
    }

    function subscribeToEvents() {
      webSocketProxy.subscribe(webSocketProxy.type.newProject, user.id, newProject => {
        if (!me.projects) {
          me.projects = [];
        }
        const foundProject = me.projects.find(project => project.id === newProject.id);
        if (!foundProject) {
          me.projects.push(newProject);
          me.receivedProjects = true;
        }
      });
    }

    function unSubscribeFromEvents() {
      if (user && user.id) {
        webSocketProxy.unsubscribe(webSocketProxy.type.newProject, user.id);
      }
    }

    function retrieveProjects() {
      projectService.getUserProjects().then(response => {
        me.projects = response;
        if (me.projects.length) {
          for (let i = 0; i < me.projects.length; i++) {
            if (me.projects[i].name.length > 20) {
              me.projects[i].tempName = me.projects[i].name.substring(0, 20) + '....';
            } else {
              me.projects[i].tempName = me.projects[i].name;
            }
            // Backwards compatibility with previous version of project JSON
            if (!me.projects[i].projectStatus) {
              me.projects[i].projectStatus = me.projects[i].status;
            }
          }
          toggleAllProjectsAction('init');
          me.ownProjects = me.projects.filter(project => project.owner.id === me.user.id);
        }
        me.receivedProjects = true;
      });
    }

    function retriveDirectories() {
      projectService.getUserDirectories().then(directories => {
        me.directories = directories.map(d => Object.assign(d, { tempName: d.name }));
      });
    }

    function createNewProject() {
      projectService.newProject({
        name: 'My New Project'
      });
    }

    function addCreatedDirectoryToLocalList(directory) {
      me.directories.unshift(Object.assign(directory, { tempName: directory.name }));
    }

    function createNewFolder(event) {
      if (event) {
        const result = {
          empty: false
        };
        if (folderName) {
          result.name = folderName;
          projectService.newDirectory(result).then(directory => {
            if (directory) {
              addCreatedDirectoryToLocalList(directory);
              folderName = '';
            }
          });
        } else {
          result.name = 'My New Folder';
          projectService.newDirectory(result).then(directory => {
            if (directory) {
              addCreatedDirectoryToLocalList(directory);
              folderName = '';
              $timeout(() => $document[0].getElementById('myAnchor0').focus(), 1000);
            }
          });
        }
      } else {
        folderName = '';
      }
    }

    function newSyncProject() {
      projectService.newSyncProject({
        name: 'New Sync Project'
      });
    }

    function setPermissionOfProjectMap() {
      me.permissionOfProjectMap = [];
      for (let i = 0; i < me.sharedProjectsWithMe.length; i++) {
        const projectId = me.sharedProjectsWithMe[i].id;
        sharedProjectsService.isReadOnly(projectId).then(response => {
          const sp = _.find(me.permissionOfProjectMap, {
            id: projectId
          })
          if (!sp) {
            me.permissionOfProjectMap.push({
              id: projectId,
              title: response ? 'View' : 'Edit'
            });
          }
        });
      }
    }

    function getSharedProjectsWithMe() {
      sharedProjectsService.getAllSharedWithMe().then(response => {
        me.sharedProjectsWithMe = response;
        if (me.sharedProjectsWithMe.length !== 0) {
          setPermissionOfProjectMap();
          webSocketProxy.subscribe(webSocketProxy.type.sharedProject, user.id, newSharedProjectWithUser => {
            if (!_.find(me.sharedProjectsWithMe, {
              id: newSharedProjectWithUser.id
            })) {
              if (newSharedProjectWithUser.owner.team.id === user.team.id) {
                me.sharedProjectsWithMe.unshift(newSharedProjectWithUser);
              }
            }
          });
          webSocketProxy.subscribe(webSocketProxy.type.unsharedProject, user.id, unsharedProjectId => {
            _.remove(me.sharedProjectsWithMe, { id: unsharedProjectId });
          });
        }
      });
    }

    function onDeselectProjectsWithMeTab() {
      webSocketProxy.unsubscribe(webSocketProxy.type.sharedProject, user.id);
      webSocketProxy.unsubscribe(webSocketProxy.type.unsharedProject, user.id);
    }

    function setActiveTab(dashboardTab) {
      me.dashboardActiveTab = dashboardTab;
      me.activeIndexTab = me.dashboardTabs.findIndex(dt => dt.label === dashboardTab.label);
      $window.scroll(0, 0);
    }

    function activateMyDictionariesTab() {
      setActiveTab(me.dashboardTabs.find(dt => dt.label === me.tabsName.myDictionaries));
    }

    function activateMyProjectsTab() {
      if (me.activeIndexTab !== 0) {
        setActiveTab(me.dashboardTabs.find(dt => dt.label === me.tabsName.myProjects));
      }
    }

    function toggleFolderOutside(index) {
      me.directories = me.directories.map((d, dIndex) => {
        const isOpen = index === dIndex ? !d.isOpen : false;
        return Object.assign(d, { isOpen })
      });
      me.isMyProjects = false;
      me.isSharedWithMe = false;
      me.toggleAllProjects = false;
      toggleDirectoryDisplay(index);
      checkAllProjects();
    }

    function checkAllProjects() {
      const openDirectories = me.directories.filter(directory => directory.isOpen);
      if (openDirectories.length > 0 && me.toggleAllProjects) {
        me.toggleAllProjects = false;
      } else if (openDirectories.length === 0 && !me.toggleAllProjects) {
        toggleAllProjectsAction();
      }
    }

    function toggleDirectoryDisplay(index) {
      const directoryId = me.directories[index] && me.directories[index].id;
      if (me.directoriesToDisplay.length > 0 && me.directoriesToDisplay.includes(directoryId)) {
        me.directoriesToDisplay.splice(me.directoriesToDisplay.indexOf(directoryId), 1);
        me.projectsFiltered = me.projects
      } else {
        me.directoriesToDisplay = [directoryId];
        filterProjects();
      }
    }

    function filterProjects() {
      me.projectsFiltered = me.directoriesToDisplay.length > 0 ? me.projects.filter(project => {
        if (project.directory) {
          return me.directoriesToDisplay.includes(project.directory.id);
        }
      }) : me.projects.filter(project => project.directory === null || project.directory === '');
      me.ownProjects = me.projects.filter(project => project.owner.id === me.user.id);
    }

    function isDirectoryOpen(project) {
      if (project && project.directory) {
        return me.directoriesToDisplay.includes(project.directory.id);
      } else {
        return false;
      }
    }

    function toggleAllProjectsAction(myProjects) {
      const directoryToOpen = $state.params.directory;
      if (myProjects === 'init' && directoryToOpen !== null) {
        const index = me.directories.findIndex(d => d.id === directoryToOpen.id);
        toggleFolderOutside(index);
      } else {
        me.toggleAllProjects = true;
        me.isMyProjects = false;
        me.isSharedWithMe = false;
        if (myProjects && myProjects === 'myProjects') {
          me.projectsFiltered = me.projects.filter(project => project.owner.id === me.user.id);
          me.directoriesToDisplay = [];
          me.directories.forEach(directory => directory.isOpen = false);
          me.isMyProjects = true;
          me.isSharedWithMe = false;
          me.toggleAllProjects = false;
        } else if (myProjects && myProjects === 'sharedWithMe') {
          me.projectsFiltered = angular.copy(me.sharedProjectsWithMe);
          me.directoriesToDisplay = [];
          me.directories.forEach(directory => directory.isOpen = false);
          me.isMyProjects = false;
          me.isSharedWithMe = true;
          me.toggleAllProjects = false;
        } else if (me.toggleAllProjects) {
          me.projectsFiltered = me.projects.filter(project => project.directory === null || project.directory === '');
          me.directoriesToDisplay = [];
          me.directories.forEach(directory => directory.isOpen = false);
        }
      }
    }

    function selectProjects(project, folder) {
      if (project.selected === true) {
        project.selected = false;
        for (let i = 0; i < selectedProjects.length; i++) {
          if (selectedProjects[i].id === project.id) {
            selectedProjects.splice(i, 1);
            break;
          }
        }
      } else {
        if (selectedProjects.length > 0) {
          if (selectedProjects[0].directory.name === folder.name) {
            project.selected = true;
            selectedProjects.push(project);
          } else {
            for (let i = 0; i < selectedProjects.length; i++) {
              selectedProjects[i].selected = false;
            }
            selectedProjects = [];
            project.selected = true;
            selectedProjects.push(project);
          }
        } else {
          project.selected = true;
          selectedProjects.push(project);
        }
      }
    }

    function getSelectedItemsIncluding(list, item) {
      moveType = list === undefined ? projectsMoveActionTypes.mainToFolder : null;
      selectedProjectId = item.projectId;
      return true;
    }

    function isFolderSelected(folder) {
      return me.directoriesToDisplay.indexOf(folder.id) >= 0 ? true : false;
    }

    function onDrop(list, main) {
      let mType = null;
      let projectId = null;
      let directoryId = null
      if (list !== null) {
        mType = projectsMoveActionTypes.folderToFolder;
        list.empty = false;
        projectId = selectedProjectId;
        directoryId = list.id;
        const result = me.projects.filter(item => item.projectId === selectedProjectId);
        if (result[0].directory) {
          alterDirectoryProjectsCount(result[0].directory.id, -1);
        }
        result[0].directory = list;
        alterDirectoryProjectsCount(directoryId, 1);
        filterProjects();
      } else {
        if (main) {
          moveType = null;
          mType = projectsMoveActionTypes.folderToMain;
          projectId = selectedProjectId;
          directoryId = null;
          const result = me.projects.filter(item => item.projectId === selectedProjectId);
          if (result[0].directory) {
            alterDirectoryProjectsCount(result[0].directory.id, -1);
          }
          result[0].directory = null;
          checkAllProjects();
          filterProjects();
        }
      }
      if (moveType === null) {
        moveType = mType;
      }
      if (list === null && moveType === projectsMoveActionTypes.mainToFolder) {
        return true;
      }
      if (moveType === projectsMoveActionTypes.folderToFolder || moveType === projectsMoveActionTypes.mainToFolder) {
        const result = me.directories.filter(item => item.id === directoryId);
        checkAllProjects();
        filterProjects();
      }
      const payload = {
        projectsId: [projectId],
        folderId: directoryId,
        isFrom: moveType
      };

      projectService.moveProject(payload);

      return true;
    }

    function showConfirmDeleteDictionary(dictionaryId) {
      notificationService.confirm('Are you sure you want to delete this dictionary and ALL its contents?')
        .then(() => deleteDictionary(dictionaryId))
        .catch(() => { });
    }

    function deleteDictionary(dictionaryId) {
      dictionaryService.delete(dictionaryId).then(() => {
        const myDictionaryIndex = _.findIndex(me.dictionaries, {
          id: dictionaryId
        });
        if (myDictionaryIndex !== -1) {
          me.dictionaries.splice(myDictionaryIndex, 1);
        }
        retrieveDictionaries(); // refetch so that the locked state can be validated again
      });
    }

    function showConfirmDeleteDirectory(directoryId) {
      const directoryProjects = _.get(me.directories.find(d => d.id === directoryId), 'totalProjectCount', 0);
      let confirmMessage = 'Are you sure you want to delete this folder';
      if (directoryProjects > 0) {
        confirmMessage += ` and all its projects`
      }
      confirmMessage += '?';
      notificationService.confirm(confirmMessage)
        .then(() => deleteDirectory(directoryId))
        .catch(() => { });
    }

    function alterDirectoryProjectsCount(directoryId, change) {
      const directoryIndex = me.directories.findIndex(d => d.id === directoryId);
      if (directoryIndex !== -1) {
        me.directories[directoryIndex].totalProjectCount += change;
      }
    }

    function deleteDirectory(directoryId) {
      const directoryProjects = me.projects.filter(p => p.directory && p.directory.id === directoryId);
      const directoryProjectsOwnedByOthers = directoryProjects.filter(p => p.owner.id !== me.user.id);
      const projectsIdsToMove = directoryProjectsOwnedByOthers.map(p => p.id);
      const directoryName = _.get(me.directories.find(d => d.id === directoryId), 'name', '');

      const onSuccess = () => {
        _.remove(me.directories, { id: directoryId });

        // me.projects.forEach(p => {
        //   if (projectsIdsToMove.includes(p.id)) {
        //     p.directory = null;
        //   }
        // });

        checkAllProjects();
        filterProjects();
        notificationService.success(`The folder${directoryName ? ' ' + directoryName : ''} was deleted successfully.`);
      };

      const onFail = (reason) => {
        const causeMessage = _.get(reason.message, '');
        notificationService.error(`Failed to delete the folder${directoryName ? ' ' + directoryName : ''}. ${causeMessage}`);
      };

      if (projectsIdsToMove.length) {
        const moveProjectsPayload = {
          projectsId: projectsIdsToMove,
          folderId: null,
          isFrom: projectsMoveActionTypes.folderToMain
        };

        const unsharedProject = Promise.all(projectsIdsToMove.map(projectId => sharedProjectsService.removeShareWithMe(projectId)));

        return unsharedProject
          .then(() => projectService.moveProject(moveProjectsPayload))
          .then(() => projectService.removeDirectory(directoryId))
          .then(onSuccess)
          .catch(onFail);
      }

      return projectService.removeDirectory(directoryId)
        .then(onSuccess)
        .catch(onFail);
    }

    function onProjectDeleted() {
      filterProjects();
    }

    function isShowTrashFolderProject(index) {
      me.projects[index].showTrashProject = true;
    }

    function isHideTrashFolderProject(index) {
      if (me.projects[index].showTrashProject || me.projects[index].showTrashProject === true) {
        me.projects[index].showTrashProject = false;
      }
    }

    function editFolderName(folder) {
      if (!folder.tempName || folder.tempName.trim() === '') {
        folder.tempName = folder.name;
      } else {
        const result = {
          name: folder.tempName,
          id: folder.id
        };
        const projects = me.projects.filter(item => {
          if (item.directory && item.directory.id) {
            return item.directory.id === folder.id;
          }
        });
        for (let index = 0; index < projects.length; index++) {
          projects[index].directory.name = folder.name;
        }
        projectService.newDirectory(result);
        const directory = me.directories.find(d => d.id === folder.id);
        if (directory) {
          directory.name = result.name;
        }
      }
    }

    function openDictionary(dictionary) {
      const modalInstance = $uibModal.open({
        animation: true,
        component: 'projectDictionaryModal',
        resolve: {
          dictionary: () => dictionary,
          languages: () => me.languages,
        }
      });

      modalInstance.result.then(modalResponse => {
        retrieveDictionaries();
      });
    }

    function retrieveDictionaries() {
      dictionaryService.getAll().then(dictionaries => {
        me.dictionaries = dictionaries;
        me.dictionariesLimitReached = dictionaries.some(d => d.locked === true);
        me.dictionariesLimitsInfoLink = SS_CONSTANT.DICTIONARIES_LIMITS_INFO_LINK;
        me.dictionariesSupported = me.user.plan.planEnum !== SS_CONSTANT.PLANS.NO_SUBSCRIPTION_PLAN;
        setDictionariesCountLabel();
      });
    }

    function retrieveLanguages() {
      const languages = storageService.get('languages');
      if (languages) {
        me.languages = languages
      } else {
        languagesService.getAll().then(languages => me.languages = languages);
      }
    }

    function createNewDictionary() {
      const modalInstance = $uibModal.open({
        animation: true,
        component: 'projectDictionaryModal',
        resolve: {
          languages: () => me.languages,
        }
      });

      modalInstance.result.then(modalResponse => {
        retrieveDictionaries();
      });
    }

    function setDictionariesCountLabel() {
      const label = me.dictionaries.length > 1 ? 'dictionaries' : 'dictionary';
      me.dictionariesCountLabel = `${me.dictionaries.length} ${label}`;
    }

    function onDestroy() {
      unSubscribeFromEvents();
    }

    function subscribeToPlan(plan) {
      const modalInstance = $uibModal.open({
        animation: true,
        backdrop: 'static',
        component: 'subscribeModal',
        resolve: {
          planInfo: () => plan
        }
      });
      modalInstance.result.then(modalResponse => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'event': 'purchase', 'pricingPlan': modalResponse.planName });
        
        notificationService.success(`Yaay! You subscribed to the ${modalResponse.planName} plan. We’re happy we will be seeing you around more often 🚀.`, true);
      });
    }

  }
}());
