(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .factory('translationService', translationService);

  translationService.$inject = ['dataService', 'sharedService', 'projectService'];

  function translationService(dataService, sharedService, projectService) {
    const endPoint = 'project';
    const service = {
      getProjectPrice: getProjectPrice,
      translateProject: translateProject
    };
    return service;

    ///////////////////

    function getProjectPrice(id, languageCount, combinedPrice = false) {
      let path = combinedPrice ? `/${id}/translation/combine-price` : `/${id}/translation/price`;
      if (languageCount) {
        path += `?languageCount=${languageCount}`;
      }
      return dataService.get(endPoint, path).then(price => {
        price.creditDetail = moment.duration(price.credit, 'minutes')._data;
        price.chargeableTime = moment.duration(price.chargeableTimeInSeconds / 60, 'minutes')._data;
        price.chargeableTimeInMinutes = price.chargeableTimeInSeconds / 60;
        price.totalTimeInMinutes = price.totalDuration / 60,
        price.totalTime = moment.duration(price.totalDuration / 60, 'minutes')._data,
        price.price = price.totalPrice,
        price.credit = price.credit,
        price.rate = price.rate,
        price.chargeableTimeInSeconds = price.chargeableTimeInSeconds,
        price.minimumChargeableTimeInSeconds = price.minimumChargeableTimeInSeconds,
        price. minimumPrice = price.minimumPrice
        return price;
      }).catch(reason => dataService.throwException(reason));
    }

    function translateProject(id, payload) {
      dataService.startSpinning();
      const path = `/${id}/translation`;
      return dataService.post(endPoint, payload, path).then(response => {
        dataService.stopSpinning();
        projectService.get(response.projectId).then(response => sharedService.redirectToProject(response));
        return response;
      }).catch(reason => {
        dataService.stopSpinning();
        dataService.throwException(reason);
      });
    }

  }
})();
