(function () {
  "use strict";

  angular.module("ss.client.modals").component("dubbingModal", {
    controller: DubbingModalComponent,
    bindings: {
      resolve: "<",
      close: "&",
      dismiss: "&",
    },
    templateUrl: "app/client/modals/dubbing-modal/dubbing-modal.component.html",
  });

  DubbingModalComponent.$inject = [
    "SS_CONSTANT",
    "GOOGLE_VOICES",
    "$uibModal",
    "transcriptionService",
    "sharedService",
    "dubbingService",
    "projectService",
    "mediaService",
    "notificationService"
  ];

  function DubbingModalComponent(
    SS_CONSTANT,
    GOOGLE_VOICES,
    $uibModal,
    transcriptionService,
    sharedService,
    dubbingService,
    projectService,
    mediaService,
    notificationService
  ) {
    const me = this;
    const user = sharedService.getUser();

    me.$onInit = onInit;

    function onInit() {
      me.currentMediaId = me.resolve.currentMediaId;
      me.project = me.resolve.project;
      me.medias = me.project.medias;
      me.languageCode = me.project.languageRegionCode.substring(0, 2);
      me.transcriptions = me.resolve.transcriptions;

      me.ttsCode = "";
      me.speakerVoiceMap = [];
      me.voices = null;

      me.currentMedia = me.medias.find((item) => item.id === me.currentMediaId);
      me.speakers = me.currentMedia.speakers.length ? me.currentMedia.speakers : [{ id: 'temp-id-0', name: 'Default speaker', color: ''}];
      me.languageCode =
        me.project.projectStatus !== SS_CONSTANT.PROJECT_STATUS.TRANSLATED
          ? me.languageCode
          : getLanguageCode(me.languageCode);

      // transcriptionService.getByMedia(me.currentMediaId).then((response) => {
      //   me.transcriptions = response;
      // });
      getSupportedVoices(me.languageCode);

      me.cancel = cancel;
      me.handleDubbingConfirm = handleDubbingConfirm;
      me.onChangeVoice = onChangeVoice;
      me.onPlayAudio = onPlayAudio;
    }

    function cancel() {
      me.dismiss();
    }

    function getLanguageCode(languageCode) {
      const regex = /(\(([a-z]+?)\))(?!.*(\(([a-z]+?)\)))/gi;
      const mediaName = me.resolve.currentMediaName;
      const matches = regex.exec(mediaName);

      return matches[2] || languageCode;
    }

    function getSupportedVoices(languageCode) {
      dubbingService.getSupportedVoices({ languageCode }).then((voices) => {
        me.voices = voices.map((voice, index) => {
          // for now, get first item for default Google tts code
          me.ttsCode = voice.languageCodes[0];
          const voiceMetadata = voice.name.split("-");
          const languageCode = voiceMetadata[0] + "-" + voiceMetadata[1];
          const voiceType = voiceMetadata[2];
          const voiceOrder = voiceMetadata[3];

          const charCodeOfA = 65;
          const foundVoice = GOOGLE_VOICES[languageCode];
          const orderNumber = index + 1; // foundVoice ? voiceOrder.charCodeAt() - charCodeOfA : "";
          voice.displayName = `${foundVoice ? foundVoice + " " + orderNumber : voice.name} | ${voice.ssmlGender}`;
          return voice;
        });
        mapSpeakerVoiceDefault(voices);
      });
    }

    function mapSpeakerVoiceDefault(voices) {
      const defaultVoice = voices[0];

      for (let i = 0; i < me.speakers.length; i++) {
        me.speakers[i] = Object.assign({}, me.speakers[i], {
          voice: defaultVoice,
        });
      }
    }

    function mapSpeakerTranscription(transcriptions, speakers) {
      const transcriptWidthSpeaker = [];

      transcriptions.map((transcription, idx) => {
        const foundSpeaker = speakers.find(
          (speaker) => speaker && transcription.speaker && speaker.id === transcription.speaker.id
        );

        const defaultVoice = me.voices[0];
        let voice = defaultVoice;

        if (foundSpeaker) {
          voice = foundSpeaker.voice;
        } else {
          const speakerDefault = speakers[0];
          voice = speakerDefault && speakerDefault.id === 'temp-id-0' ? speakerDefault.voice : defaultVoice;
        }

        transcriptWidthSpeaker.push({
          ssmlGender: voice.ssmlGender,
          voiceName: voice.name,
        });
      });

      return transcriptWidthSpeaker;
    }

    function onChangeVoice(index) {
      // console.log({ index });
      // console.log(me.speakerWithTranscript[index]);
    }

    function onPlayAudio(speaker) {
      const audio = document.getElementById("audio-" + speaker.id);
      const button = document.getElementById("button-" + speaker.id);
      const isPlaying = !audio.paused;

      audio.load();
      if (isPlaying) {
        audio.pause();
        button.innerHTML = '<i class="fa fa-play" aria-hidden="true"></i>';
      } else {
        audio.play();
        button.innerHTML = '<i class="fa fa-pause" aria-hidden="true"></i>';
      }
    }

    function handleDubbingConfirm() {
      try {
        const projectId = me.project.id;
        const transcriptWidthSpeaker = mapSpeakerTranscription(
          me.transcriptions,
          me.speakers
        );

        const dubbingData = {
          mediaId: me.currentMediaId,
          ttsCode: me.ttsCode,
          speakers: transcriptWidthSpeaker,
          projectName: me.project.name
        };

        if(!dubbingData.speakers.length || !me.transcriptions.length || dubbingData.speakers.length !== me.transcriptions.length) {
          throw new Error("Transcript row must be assigned to a speaker");
        }

        openPaymentModal(dubbingData);

      } catch(e) {
        notificationService.error(e.message);
      }
    }

    function openPaymentModal(dubbingData) {
      if (me.currentMediaId) {
        const currentMedia = me.medias.find(media => media.id === me.currentMediaId);
        if (currentMedia.type !== 'video') {
          notificationService.warning('Current file is not a video. Please select another.');
          return;
        }
      }
      const dubParams = getDubParams();

      mediaService.getProjectsPrice(me.project.id, dubParams).then(price => {
        me.close();
        $uibModal.open({
          animation: true,
          component: 'dubPaymentModal',
          resolve: {
            price: () => price,
            projectId: () => me.project.id,
            planEnum: () => user.plan.planEnum,
            dubParams: () => dubParams,
            payload: () => dubbingData
          }
        });
      });
    }

    function getDubParams() {
      const dub = {
        payType: 'DUB'
      };
      if (me.currentMediaId) {
        dub.currentMediaId = me.currentMediaId
      };
      return dub;
    }
  }
})();
