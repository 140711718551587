(function () {
  'use strict';

  angular
    .module('ss.client.modals')
    .component('languageDetailModal', {
    controller: LanguageDetailModal,
    bindings: {
      resolve: '=',
      dismiss: '&'
    },
      templateUrl: 'app/admin/modals/language-detail-modal/language-detail-modal.component.html'
  });

  LanguageDetailModal.$inject = ['$uibModal', 'languagesAdminService', 'notificationService'];

  function LanguageDetailModal($uibModal, languagesAdminService, notificationService) {
    const me = this;

    me.$onInit = onInit;
    me.cancel = cancel;
    me.saveChange = saveChange;
    me.SpeechApiCodeToName = SpeechApiCodeToName;

    me.listFetched = false;
    me.supportedApis = [];
    me.primarySpeechApi = null;
    me.backupSpeechApi = null;
    me.isLoading = false;

    function onInit() {
      me.listFetched = false;
      me.primarySpeechApi = SpeechApiIdToCode(me.resolve.langRegion.defaultSpeechApi);
      me.backupSpeechApi = SpeechApiIdToCode(me.resolve.langRegion.backupSpeechApi);

      languagesAdminService.getSupportEngines(`${me.resolve.langRegion.langCode}-${me.resolve.langRegion.regionCode}`).then((data) => {
        me.supportedApis = data;
        me.listFetched = true;
      }).catch((err) => { console.log(err) });
    }

    function cancel() {
      me.dismiss();
    }

    function saveChange() {
      me.isLoading = true;

      languagesAdminService.updateSpeechApi(`${me.resolve.langRegion.langCode}-${me.resolve.langRegion.regionCode}`, me.resolve.langRegion.regionId, me.primarySpeechApi, me.backupSpeechApi || null).then((res) => {
        cancel();
        me.resolve.FetchLanguages();
      }).catch((err) => {
        if(err.message.includes('$http:baddata')) { // Still a success
          cancel();
          me.resolve.FetchLanguages();
        } else {
          console.log(err.message);
        }
      }).finally(() => {
        me.isLoading = false;
      })
    }

    function SpeechApiIdToCode(code) {
      switch(code) {
        case "1": return "watson";
        case "2": return "google";
        case "4": return "speechmatics";
        case "5": return "speechmaticsv1";
        case "6": return "revai";
        case "7": return "avflow";
        case "8": return "whisper";
        default: return null;
      }
    }

    function SpeechApiCodeToName(code) {
      switch(code) {
        case "watson": return "Watson";
        case "google": return "Google";
        case "speechmatics": return "Speechmatics v2";
        case "speechmaticsv1": return "Speechmatics v1";
        case "revai": return "RevAI";
        case "avflow": return "AVFlow";
        case "whisper": return "Whisper";
        default: return "Unknown";
      }
    }
  }
})();
