(function () {
  'use strict';
  angular
    .module('ss.client.components')
    .component('summaryCost', {
      controller: SummaryCostController,
      bindings: {
        totalTimeText: '@',
        totalTimeText2: '@',
        price: '<',
        price2: '<',
        withTranslate: '<'
      },
      templateUrl: 'app/client/components/summary-cost/summary-cost.component.html'
    });

  SummaryCostController.$inject = ['$filter'];

  function SummaryCostController($filter) {
    const me = this;

    me.formatPrice = formatPrice;
    me.formatPriceSum = formatPriceSum;

    function formatPrice(price) {
      const formattedRatePrice = formatRatePrice(price.rate);
      const [whole, fraction] = `${$filter('currency')(price.price, '$')}`.split('.');

      return {
        whole,
        fraction,
        formattedRatePrice
      };
    }

    function formatPriceSum(price, price2) {
      const [whole, fraction] = `${$filter('currency')(price.price + ((price2 ? price2.price : 0)), '$')}`.split('.');

      return {
        whole,
        fraction
      };
    }

    function formatRatePrice(rate) {
      const fraction = rate % 1;
      if (fraction > 0) {
        return Number(rate).toFixed(2);
      }

      return rate - fraction;
    }
  }
})();
