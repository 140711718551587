(function () {
  'use strict';

  angular
    .module('ss.client.services')
    .factory('mediaService', mediaService);

  mediaService.$inject = ['$rootScope', '$window', 'customAnalyticsService', 'dataService',
    '$q', 'webSocketProxy', 'notificationService', '$timeout'
  ];

  function mediaService($rootScope, $window, customAnalyticsService, dataService,
    $q, webSocketProxy, notificationService, $timeout) {
    const endPointMedia = 'media';
    const endPointProjects = 'projects';
    const maxLocalSize = 5000000000;
    const maxSize = 20000000000;
    const errorMessageRemoteFileMaxSize =
      `The file size is too large (>20GB). Transcode it to a smaller size or use our <a target="_blank" rel="noopener noreferrer" href="https://www.simonsaysai.com/help/2657471-download-the-macos-app-fcp-x-extension">macOS application</a> instead.`;
    var errorMessageLocalUploader =
      `The file is greater than 5GB and too large to upload directly. Alternatives:
        1) import it via Google Drive, Dropbox or Box.
        2) transcode it to a smaller size before importing.
        Or 3. use the <a target="_blank" rel="noopener noreferrer" href="https://www.simonsaysai.com/help/2657471-download-the-macos-app-fcp-x-extension">Mac app</a> which does not have file size restrictions.`;

    const progressByProject = {};
    const uploaderType = {
      local: 'LOCAL',
      dropbox: 'DROPBOX',
      drive: 'DRIVE',
      box: 'BOX'
    };
    const cancellableUploaders = {};
    const service = {
      maxSize: maxSize,
      maxLocalSize: maxLocalSize,
      errorMessageRemoteFileMaxSize: errorMessageRemoteFileMaxSize,
      errorMessageLocalUploader: errorMessageLocalUploader,
      uploaderType: uploaderType,
      progressByProject: progressByProject,
      create: create,
      getSignedUrl: getSignedUrl,
      startListeningUploadProgress: startListeningUploadProgress,
      stopListeningUploadProgress: stopListeningUploadProgress,
      isMediaUploading: isMediaUploading,
      saveMediaData: saveMediaData,
      delete: deleteMedia,
      uploadFile: uploadFile,
      uploadLocalFile: uploadLocalFile,
      startListeningUploadEvents: startListeningUploadEvents,
      tryToLock: tryToLock,
      tryToUnLock: tryToUnLock,
      tryToUnLockSync: tryToUnLockSync,
      hasProjectUploads: hasProjectUploads,
      hasProjectLocalUploads: hasProjectLocalUploads,
      prepareRemoteFile: prepareRemoteFile,
      checkoutTranscribe: checkoutTranscribe,
      getProjectsPrice: getProjectsPrice,
      getStatusLabel: getStatusLabel,
      getMediaProgress: getMediaProgress,
      getMediaProgressInKb: getMediaProgressInKb,
      encodeFramePath: encodeFramePath,
      removeUploadedFile: removeUploadedFile,
      leaveProjectUpload: leaveProjectUpload
    };
    return service;

    ////////////////////////////////////

    function hasNotStartedUploadingOrDownloading(media) {
      const incompleteStates = ['empty', 'preparing'];
      if (media.fileUploadType === uploaderType.local) {
        incompleteStates.push('uploading');
      }
      return incompleteStates.indexOf(media.status) !== -1;
    }

    function hasAnyMediaNotStartedUploading() {
      let foundMediaItem;
      angular.forEach(progressByProject, projectItem => {
        angular.forEach(projectItem.medias, mediaItem => {
          if (hasNotStartedUploadingOrDownloading(mediaItem)) {
            foundMediaItem = mediaItem;
          }
        });
      });
      return !!foundMediaItem;
    }

    function create(payload) {
      const path = `/${payload.project.id}/media/`;
      delete payload.project;

      return dataService.post(endPointProjects, payload, path).then(response => {
        response.status = 'preparing';
        return response;
      }).catch(reason => onError(reason));
    }

    function getSignedUrl(projectId, payload) {
      const path = `/${projectId}/signed-url-to-upload`;
      return dataService.post(endPointProjects, payload, path).catch(reason => onError(reason));
    }

    function startListeningUploadProgress(projectId) {
      if (!progressByProject[projectId]) {
        progressByProject[projectId] = {};
      }
      if (!progressByProject[projectId].medias) {
        progressByProject[projectId].medias = {};
      }
      webSocketProxy.subscribe(webSocketProxy.type.uploadProgress, projectId, response => {
        const mediaId = response.mediaId;
        const nextProgress = parseInt(response.progress, 10);
        const status = nextProgress > 39 ? nextProgress > 99 ? 'ready' : 'transcoding' : 'uploading';

        if (!progressByProject[projectId].medias[mediaId]) {
          progressByProject[projectId].medias[mediaId] = {};
        }
        const currentProgress = progressByProject[projectId].medias[mediaId].progress;
        if (!currentProgress || (nextProgress > currentProgress)) {
          progressByProject[projectId].medias[mediaId] = {
            status: status,
            progress: nextProgress,
            local: false
          };
          $rootScope.$emit('mediaProgressChange', createMediaProgressUpdate(mediaId, projectId, progressByProject[projectId].medias[mediaId]));
        }
      });
    }

    function stopListeningUploadProgress(projectId) {
      webSocketProxy.unsubscribe(webSocketProxy.type.uploadProgress, projectId);
      delete progressByProject[projectId];
    }

    function isMediaUploading(projectId, mediaId) {
      const project = progressByProject[projectId] || {};
      const medias = project.medias || {};
      const media = medias[mediaId];

      return media && media.progress < 100;
    }

    function saveMediaData(payload) {
      return dataService.put(endPointMedia, payload, '/' + payload.id)
        .then(() => customAnalyticsService.trackEvent('Media', 'Save TimeCode success', null, null))
        .catch(reason => {
          customAnalyticsService.trackEvent('Media', 'Save TimeCode error', null, reason);
          dataService.throwException(reason, `Error! Your timecode couldn't be saved. Refresh the page and try again.`);
        });
    }

    function deleteMedia(projectId, id) {
      return dataService.delete(endPointMedia, '/' + id).then(response => {
        // remove progress from project once deleted (if we did it earlier, it'd be recreated from socket update)
        delete progressByProject[projectId].medias[id];
        customAnalyticsService.trackEvent('Project Open', 'media deleted', null, null);
        return response;
      }).catch(reason => {
        // remove even if failed
        delete progressByProject[projectId].medias[id];
        customAnalyticsService.trackEvent('Project Open', 'media delete error', null, reason);
        dataService.logException(reason);
      });
    }

    function uploadFile(payload) {
      const {
        preparedFile,
        media,
        projectId
      } = payload;
      customAnalyticsService.trackEvent('Upload', 'Uploading file', null, null);
      const mediaId = media.id;
      const path = `/${mediaId}/upload`;
      const config = {
        uploadEventHandlers: {
          progress: event => {
            if (preparedFile.type === uploaderType.local) {
              progressEvent(event, projectId, mediaId);
            }
          }
        }
      };

      return dataService.post(endPointMedia, JSON.stringify(preparedFile), path, config).then(response => {
        progressByProject[projectId].medias[mediaId].status = '🏎 Starting';
        response.updatedMediaId = mediaId;
        return response;
      }).catch(reason => uploadError(reason));
    }

    function uploadLocalFile(file, objectKey, mediaId, projectId, signedUrl) {
      if (!progressByProject[projectId]) {
        progressByProject[projectId] = {};
      }
      if (!progressByProject[projectId].medias) {
        progressByProject[projectId].medias = {};
      }
      progressByProject[projectId].medias[mediaId] = {
        status: 'uploading',
        progress: 0,
        local: true
      };
      customAnalyticsService.trackEvent('Upload', 'Uploading file', null, null);
      const canceler = $q.defer();
      const config = {
        timeout: canceler.promise,
        headers: {
          'Content-Type': file.type
        },
        uploadEventHandlers: {
          progress: event => progressEvent(event, projectId, mediaId)
        }
      };

      const promise = dataService.uploadFile(signedUrl, file, config).then(response => {
        const payload = {
          fileName: objectKey,
          type: 'LOCAL',
          fileSize: file.size
        };
        pushMessageQueueForUpload(mediaId, payload);
        const mediaProcessing = progressByProject[projectId].medias[mediaId];
        if (mediaProcessing) {
          mediaProcessing.status = '🏎 Starting';
          response.mediaId = mediaId;
          return response;
        }
      }).catch(reason => uploadError(reason));

      const uploadFile = {
        canceler: canceler,
        promise: promise
      };
      cancellableUploaders[mediaId] = uploadFile;

      return uploadFile;
    }

    function progressEvent(event, projectId, mediaId) {
      if (event.lengthComputable) {
        // Transcoding needs to be taken under consideration. That's above on the uploadProgress websocket connection.
        const percentComplete = Math.ceil((event.loaded / event.total) * 40);
        const mediaProcessing = progressByProject[projectId].medias[mediaId];
        const loadedMb = event.loaded / 1000000;
        const totalMb = event.total / 1000000;

        if (mediaProcessing) {
          mediaProcessing.status = 'uploading';
          mediaProcessing.progress = percentComplete;
          mediaProcessing.loadedMb = loadedMb.toFixed(1);
          mediaProcessing.totalMb = totalMb.toFixed(1);
        }
        $rootScope.$emit('mediaProgressChange', createMediaProgressUpdate(mediaId, projectId, mediaProcessing));
      }
    }

    function pushMessageQueueForUpload(mediaId, payload) {
      const path = `/${mediaId}/upload`;
      dataService.post(endPointMedia, payload, path).catch(error => {
        customAnalyticsService.trackEvent('Upload', 'Error upload file', error, null);
        dataService.logException('Error upload file');
      });
    }

    function startListeningUploadEvents() {
      $window.onbeforeunload = event => {
        if (hasAnyMediaNotStartedUploading()) {
          const dialogText = 'There are some uploads in process. If you refresh the web page, your uploaded files will be incomplete. Are you sure?';
          event.returnValue = dialogText;
          return dialogText;
        }
      };
      // lets avoid memory leaks
      $rootScope.$on('$destroy', () => uploadFileListener());
    }

    function tryToLock(mediaId, forceLock) {
      const path = `/${mediaId}/lock`;
      const payload = {
        forceLock: forceLock
      };
      return dataService.post(endPointMedia, payload, path);
    }

    function tryToUnLock(mediaId) {
      const path = `/${mediaId}/unlock`;
      return dataService.post(endPointMedia, path);
    }

    function tryToUnLockSync(mediaId) {
      const path = `/${mediaId}/unlock`;
      return dataService.post(endPointMedia, path);
    }

    function hasProjectUploads(projectId) {
      const medias = (progressByProject[projectId] || {}).medias;
      return Object.keys(medias || {}).some(mediaId => medias[mediaId].status !== 'ready');
    }

    function hasProjectLocalUploads(projectId) {
      const medias = (progressByProject[projectId] || {}).medias;
      return Object.keys(medias || {}).some(mediaId => medias[mediaId].status === 'uploading' && medias[mediaId].local);
    }

    function getStatusLabel(projectId, mediaId, projectMedias) {
      const progressStatus = (((progressByProject[projectId] || {}).medias || {})[mediaId] || {}).status;
      const mediaProjectStatus = (projectMedias.find(media => media.id === mediaId) || {}).status;
      const status = progressStatus || mediaProjectStatus;

      return status && status.toLowerCase() === 'ready' ? 'ready!' : status && status.toLowerCase() === 'transcoding' ? 'preparing' : status;
    }

    function getMediaProgress(mediaId, projectId) {
      return mediaId && progressByProject[projectId] && progressByProject[projectId].medias && progressByProject[projectId].medias[mediaId] ? progressByProject[projectId].medias[mediaId].progress : null;
    }

    function getMediaProgressInKb(mediaId, projectId) {
      return mediaId && progressByProject[projectId] && progressByProject[projectId].medias && progressByProject[projectId].medias[mediaId] ? [progressByProject[projectId].medias[mediaId].loadedMb, progressByProject[projectId].medias[mediaId].totalMb] : null;
    }

    function prepareRemoteFile(uploaderType, projectId, mediaId, file) {
      return new Promise((resolve, reject) => {
        const fileSize = file.bytes || file.size;
        if (fileSize >= maxSize) {
          const reason = {
            message: errorMessageRemoteFileMaxSize,
            mediaId: mediaId
          };
          reject(reason);
        }
        if (!progressByProject[projectId]) {
          progressByProject[projectId] = {};
        }
        if (!progressByProject[projectId].medias) {
          progressByProject[projectId].medias = {};
        }
        progressByProject[projectId].medias[mediaId] = {
          status: 'preparing',
          prepareProgress: 100,
          local: false
        };
        const payload = {
          type: uploaderType,
          fileName: file.name,
          fileSize: fileSize,
          linkToFile: file.link,
          accessToken: file.accessToken
        };
        resolve(payload);
      });
    }

    function createMediaProgressUpdate(mediaId, projectId, info) {
      const mediaProgressUpdate = angular.copy(info);
      mediaProgressUpdate.mediaId = mediaId;
      mediaProgressUpdate.projectId = projectId;

      return mediaProgressUpdate;
    }

    function encodeFramePath(framePath) {
      if (framePath && framePath.indexOf('http') !== -1) {
        const path = framePath.substring(framePath.lastIndexOf('/') + 1);
        const hostAndContext = framePath.substring(0, framePath.lastIndexOf('/') + 1);
        return hostAndContext + $window.encodeURIComponent(path);
      }
      return framePath;
    }

    function checkoutTranscribe(projectId, useSavedCard, shouldSaveCard, card, withQueryParams, burnInParams, translateTo = []) {
      // quick hack to resolve issue with users who do not have card details on record
      let replaceCard;
      // card details provided
      if (useSavedCard === undefined) {
        if (card.cvv) {
          replaceCard = true;
          // using promo credit
        } else {
          replaceCard = false;
        }
      } else {
        replaceCard = !useSavedCard
      }

      const initParams = {
        projectId: projectId,
        replaceCard
      };

      if(translateTo.length > 0) {
        initParams.includeTranslation = true;
      }

      let queryString = dataService.httpParamSerializer(initParams, '?');
      if (withQueryParams) {
        const cardParams = {
          cardHolderName: card.cardHolderName,
          number: card.cardNumber,
          exp_month: card.expirationMonth,
          exp_year: card.expirationYear,
          cvc: card.cvv,
          saveCard: shouldSaveCard
        };
        queryString += dataService.httpParamSerializer(cardParams, '&');
      }
      if (burnInParams) {
        queryString += dataService.httpParamSerializer(burnInParams, '&');
      }
      dataService.startSpinning();

      var promise = translateTo.length > 0 ? dataService.post(endPointProjects, { targetLanguageIds: translateTo }, '/checkout' + queryString) : dataService.post(endPointProjects, '/checkout' + queryString)
      return promise.then(response => {
          dataService.stopSpinning();
          if (response.response !== 'OK') {
            dataService.throwException(response);
          }
          return response;
        }).catch(reason => {
          dataService.stopSpinning();
          dataService.throwException(reason);
        });
    }

    function getProjectsPrice(projectId, burnInParams) {
      const params = {
        projectId: projectId
      };
      let queryString = dataService.httpParamSerializer(params, '?');
      if (burnInParams) {
        queryString += dataService.httpParamSerializer(burnInParams, '&');
      }
      return dataService.get(endPointProjects, '/getprice' + queryString).then(response => {
        const price = response;
        const priceResponse = {
          totalTimeInMinutes: price.totalDuration / 60,
          totalTime: moment.duration(price.totalDuration / 60, 'minutes')._data,
          chargeableTimeInMinutes: price.chargeableTimeInSeconds / 60,
          chargeableTime: moment.duration(price.chargeableTimeInSeconds / 60, 'minutes')._data,
          price: price.totalPrice,
          credit: price.credit,
          creditDetail: moment.duration(price.credit, 'minutes')._data,
          rate: price.rate,
          chargeableTimeInSeconds: price.chargeableTimeInSeconds,
          minimumChargeableTimeInSeconds: price.minimumChargeableTimeInSeconds,
          minimumPrice: price.minimumPrice
        };
        return priceResponse;
      }).catch(reason => dataService.logException(reason));
    }

    function onError(reason) {
      let message = 'Error upload file';
      if (reason && reason.data && reason.data.error) {
        message = reason.data.error;
        customAnalyticsService.trackEvent('Upload', 'Error upload file', message, null);
      }
      dataService.throwException(reason, message);
    }

    function uploadError() {
      if (reason.status !== 502) {
        delete progressByProject[projectId].medias[mediaId];
        reason.mediaId = mediaId;
        dataService.throwException(reason, file.name + ' upload cancelled.', mediaId)
      }
    }

    function removeUploadedFile(mediaId) {
      delete cancellableUploaders[mediaId];
    }

    function leaveProjectUpload(projectId) {
      return new Promise((resolve, reject) => {
        if (!hasProjectUploads(projectId)) {
          resolve();
        } else {
          notificationService.confirm('If you leave the page, your upload will stop. Are you sure you want to proceed?')
            .then(() => {
              if (!angular.equals({}, cancellableUploaders)) {
                for (const uploader in cancellableUploaders) {
                  deleteMediaUploading(uploader, projectId);
                }
                $timeout(() => {
                  resolve();
                }, 1500);
              } else {
                resolve();
              }
            }).catch(() => {
              reject();
            });
        }
      });
    }

    function deleteMediaUploading(mediaId, projectId) {
      customAnalyticsService.trackEvent('Project Open', 'click delete media button', null, null);
      if (cancellableUploaders[mediaId]) {
        cancellableUploaders[mediaId].canceler.resolve('abort by user');
      }
      deleteMedia(projectId, mediaId);
      removeUploadedFile(mediaId);
    }

  }
})();
