(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('languagesPageComponent', {
      controller: LanguagesPageComponent,
      bindings: {},
      templateUrl: 'app/admin/pages/languages-page/languages-page.component.html'
    })

    LanguagesPageComponent.$inject = ['$uibModal', 'languagesAdminService'];

  function LanguagesPageComponent($uibModal, languagesAdminService) {
    const me = this;

    // Functions
    me.$onInit = onInit;
    me.onClickMappedLanguage = onClickMappedLanguage;

    // Variables
    me.originalLanguages = [];
   
    function onInit() {
      FetchLanguages();
    }

    function FetchLanguages() {
      languagesAdminService.getAll().then(response => {
        me.originalLanguages = response;
        me.mappedLanguages = [];

        me.originalLanguages.forEach((l) => {
          if(l.regions.length > 0) 
            l.regions.forEach((r) => {
              let label = `${l.label} (${r.name})`;
              me.mappedLanguages.push({
                langId: l.id,
                regionId: r.id,
                langCode: l.code,
                regionCode: r.code,
                direction: l.direction,
                label: label !== 'English (Automatic)' ? label : 'Language Detection (Automatic)',
                defaultSpeechApi: r.defaultSpeechApi,
                backupSpeechApi: r.backupSpeechApi || null,
                diarizationSupported: r.diarizationSupported
              })
            });
        });

        me.mappedLanguages = me.mappedLanguages.sort((a, b) => a.label.localeCompare(b.label))
        
        document.querySelector("#loading-bar").remove();
        document.querySelector("#loading-bar-spinner").remove();

      });
    }

    function onClickMappedLanguage(lr) {
      $uibModal.open({
        animation: true,
        backdrop: "static",
        component: "languageDetailModal",
        resolve: {
          mappedLanguages: () => me.mappedLanguages,
          langRegion: () => lr,
          FetchLanguages: () => FetchLanguages
        },
      });
    }
  }
}());
