(function () {
  'use strict';

  angular
    .module('ss.common.filters')
    .filter('speechApiIdToName', speechApiIdToName);

  function speechApiIdToName() {
    return function(input) {
      switch(input) {
        case "1": return "Watson";
        case "2": return "Google";
        case "4": return "Speechmatics v2";
        case "5": return "Speechmatics v1";
        case "6": return "RevAI";
        case "7": return "AVFlow";
        case "8": return "Whisper";
        default: return null; // Fallback
      }
    }
  }
})();
