(function () {
  'use strict';

  angular
    .module('ss.client.components')
    .component('creditCard', {
      controller: CreditCardComponent,
      bindings: {
        upgradePaymentOption: '=?',
        displaySaveCardOption: '=',
        useSavedCard: '=',
        submitFn: '&',
        chargeableTime: '=',
        isSubscribing: '<',
        isAddingCredit: '<',
        disabledSubmitButton: '=',
        submitButtonText: '<?',
        action: '@',
        paymentMode: '@'
      },
      templateUrl: 'app/client/components/credit-card/credit-card.component.html'
    });

  CreditCardComponent.$inject = ['sharedService'];

  function CreditCardComponent(sharedService) {
    const me = this;

    me.$onInit = onInit;
    me.getCardLastDigits = getCardLastDigits;
    me.getCardType = getCardType;
    me.hasError = hasError;
    me.onSubmit = onSubmit;
    me.validateUpgradePaymentOption = validateUpgradePaymentOption;
    me.user = sharedService.getUser();
    me.creditCardForm = {};

    const DEFAULT_CARD_DIGITS_PATTERN = '9999 9999 9999 9999';
    const DEFAULT_CVC_PATTERN = '999';
    const DEFAULT_SELECTED_CARD_TYPE = {
      name: 'default',
      pattern: DEFAULT_CARD_DIGITS_PATTERN,
      cvcPattern: DEFAULT_CVC_PATTERN
    };
    const CREDIT_CARD_TYPES = [{
      name: 'americanExpress',
      length: 15,
      beginWithNumber: 3,
      pattern: '9999 999999 99999',
      cvcPattern: '999?9'
    },
    {
      name: 'visa',
      length: 16,
      beginWithNumber: 4,
      pattern: DEFAULT_CARD_DIGITS_PATTERN,
      cvcPattern: DEFAULT_CVC_PATTERN
    },
    {
      name: 'masterCard',
      length: 16,
      beginWithNumber: 5,
      pattern: DEFAULT_CARD_DIGITS_PATTERN,
      cvcPattern: DEFAULT_CVC_PATTERN
    }
    ];
    me.selectedCardType = DEFAULT_SELECTED_CARD_TYPE;

    function onInit() {
      if (me.action !== 'updateCard' && me.user.cardDigits) {
        me.useSavedCard = true;
      }
      me.submitButtonText = me.submitButtonText || `Let's do this`;
      me.shouldSaveCard = false;
      me.isSubscribing = false;
      me.minYear = new Date().getFullYear().toString().substr(-2);
      me.uiMaskCardNumberOptions = {
        allowInvalidValue: true,
        maskDefinitions: {
          '*': new RegExp('^[3-5]')
        }
      };
      if (me.upgradePaymentOption) {
        me.updateCreditCardSubscription = true;
      }
    }

    function getCardType() {
      if (me.cardNumber && me.cardNumber.length >= 1) {
        const firstDigitCardString = me.cardNumber.charAt(0);
        const firstDigitCardNumber = parseInt(firstDigitCardString, 10);

        var newCardType = CREDIT_CARD_TYPES.filter(type => type.beginWithNumber === firstDigitCardNumber)[0];

        if(me.selectedCardType != newCardType)
          me.selectedCardType = newCardType;
      } else if (!me.cardNumber) {
        me.selectedCardType = DEFAULT_SELECTED_CARD_TYPE;
      }
    }

    function getCardLastDigits() {
      return me.paymentMode === 'subscribe' ? me.user.subscriptionCardDigits : me.user.cardDigits;
    }

    function hasError(field) {
      if(me.selectedCardType.name == 'americanExpress' && field == 'cvv' && me.creditCardForm[field].$dirty && me.creditCardForm[field].$invalid && me.creditCardForm[field].$$rawModelValue) {
        var value = me.creditCardForm[field].$$rawModelValue;
        if(value.length >= 3 && value.length <= 4) return false;
        else true;
      }

      return (me.creditCardForm[field].$dirty && me.creditCardForm[field].$invalid) || (me.creditCardForm.$submitted && me.creditCardForm[field].$invalid);
    }

    function validateUpgradePaymentOption() {
      me.disabledSubmitButton = !me.updateCreditCardSubscription && !me.useSavedCard ? true : false;
    }

    function onSubmit() {
      if (me.creditCardForm.$valid || me.useSavedCard || !me.chargeableTime) {
        const card = {
          cardHolderName: me.cardHolderName,
          cardNumber: me.cardNumber,
          expirationMonth: me.expirationMonth,
          expirationYear: me.expirationYear,
          cvv: me.cvv
        };
        me.submitFn({
          useSavedCard: me.useSavedCard,
          shouldSaveCard: me.shouldSaveCard,
          card: card,
          shouldUpdatePayment: me.useSavedCard,
          shouldUpdateSubscriptionPayment: me.updateCreditCardSubscription
        });
      }
    }

  }
})();
