angular.module("ngConstants", [])

.constant("host", "https://simonsaysai.com/")

.constant("ogUrl", "https://app.simonsaysai.com/")

.constant("LANDING_PAGE_URL", "https://simonsaysai.com")

.constant("PUBLIC_SITE_URL", "https://public.simonsaysai.com")

.constant("PUBLIC_SITE_TRANSCRIPT_URL", "https://transcript.simonsaysai.com")

.constant("AUTH0_CONFIG", {
	"clientID": "dS6Xi5tLNHwpXFGl1IdrO8D2j8dc6Lvu",
	"domain": "auth.simonsaysai.com",
	"responseType": "token id_token",
	"redirectUri": "https://app.simonsaysai.com/callback",
	"scope": "openid profile email read:messages",
	"audience": "https://simonsays.ai",
	"leeway": 180
})

.constant("APP_CONFIG", {
	"ROUTE_PREFIX": "/app",
	"ROUTE_ADMIN_PREFIX": "/admin",
	"LOGOUT_REDIRECT_TO": "https://app.simonsaysai.com"
})

;