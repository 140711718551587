(function () {
  'use strict';

  angular
    .module('ss.client.pages')
    .component('appPageComponent', {
      controller: AppPageComponent,
      bindings: {
        user: '<'
      },
      templateUrl: 'app/client/pages/app-page/app-page.component.html'
    });

  AppPageComponent.$inject = ['$window', 'APP_CONFIG', 'authService', 'stateService', 'usersService', '$rootScope',
    'notificationService', 'fullStoryWrapperService', 'sharedService', 'webSocketProxy', '$sce', '$uibModal'
  ];

  function AppPageComponent($window, APP_CONFIG, authService, stateService, usersService, $rootScope,
    notificationService, fullStoryWrapperService, sharedService, webSocketProxy, $sce, $uibModal) {

    const me = this;
    let userInfoChangeListener;
    const appId = 'loirlq6j';
    const statesWithoutHeader = ['LandingPageComponent', 'AuthCallbackPageComponent', 'app.projectTranscript'];
    const statesWithoutFooter = ['app.projectTranscript'];

    me.$onInit = onInit;
    me.$onChanges = onChanges;
    me.$onDestroy = onDestroy;
    me.canHideHeader = canHideHeader;
    me.canHideFooter = canHideFooter;
    me.isPageDashboard = isPageDashboard;
    me.isLandinPage = isLandinPage;
    me.onMenuItemClick = onMenuItemClick;
    me.logout = logout;
    me.login = login;
    me.userHasCredits = userHasCredits;
    me.isTeamOwner = isTeamOwner;
    me.goToInviteSection = goToInviteSection;
    me.subscribeToPlan = subscribeToPlan;
    me.openAddCreditModal = openAddCreditModal;
    me.isPageUpload = isPageUpload;
    me.user = {};
    me.credit = {};

    function onInit() {
      me.logoLinkHref = APP_CONFIG.ROUTE_PREFIX;
      if (localStorage.getItem('platform')) {
        statesWithoutFooter.push('app.projectTranscript');
      }
      setProfitWell();
    }

    function setProfitWell() {
      const scriptSelector = `script[id='profitwell-js']`;
      const element = document.querySelector(scriptSelector);
      const trustedContent = $sce.trustAsHtml(
        `(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)}; a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
      profitwell('start', {
        'user_email': '${me.user.email}'
        });`);
      element.innerHTML = trustedContent;
    }

    function onChanges(changes) {
      if (changes.user && changes.user.currentValue) {
        const user = changes.user.currentValue;
        sharedService.setUser(user);
        sharedService.setCredit(getCredit(user));
        subscribeToUserDataUpdates();
        tryToInitializeIntercom(user);
        tryToInitializeCrisp(user);
        fullStoryWrapperService.identify(user);
        me.user = user;
        me.credit = sharedService.getCredit();
      } else {
        unSubscribeToUserDataUpdates();
      }
      buildMenuItemList();
    }

    function tryToInitializeIntercom(user) {
      if (user && user.id) {
        if (!sharedService.getIntercomInitialized()) {
          const userInfoForIntercom = angular.copy(user);
          userInfoForIntercom.app_id = appId;
          usersService.getHash().then(response => {
            userInfoForIntercom.user_hash = response.value;
            $window.Intercom('boot', userInfoForIntercom);
            sharedService.setIntercomInitialized(true);
          });
        } else {
          $window.Intercom('update');
        }
      }
    }

    function tryToInitializeCrisp(user) {
      if(!user || !user.id) return;
      if (sharedService.getCrispInitialized()) return;

      function pick(object, keys) {
        return keys.reduce((o, k) => {
          if(!object[k]) return ot;

          o[k] = object[k];
          return o;
        }, {});
      }

      // function convertObjToCrispValue(object) {
      //   const result = [];
      //   for (const [key, value] of Object.entries(object)) {
      //     result.push([key, value]);
      //   }
      //   return result;
      // }

      const userInfoForCrisp = angular.copy(user);
      console.log({userInfoForCrisp})
      const userInfo = pick(userInfoForCrisp, ['id', 'email', 'fullName', 'avatar']);
      // const userPlanInfo = pick(userInfoForCrisp.plan, ['type', 'planEnum']);

      // temporarily comment out in order to wait for new build BE
      // usersService.getCrispHash().then(response => {
      //   const hash = response.value;

      //   $window.$crisp.push(["set", "user:email", [userInfo.email, hash]]);
      //   $window.$crisp.push(["set", "user:nickname", [userInfo.fullName]]);
      //   $window.$crisp.push(["set", "user:avatar", [userInfo.avatar]]);
      // });

      $window.$crisp.push(["set", "user:email", [userInfo.email]]);
      $window.$crisp.push(["set", "user:nickname", [userInfo.fullName]]);
      $window.$crisp.push(["set", "user:avatar", [userInfo.avatar]]);

      // Data Crisp
      // $window.$crisp.push(["set", "session:data", [convertObjToCrispValue(userPlanInfo)]]);

      // event Crisp [text_1, data_1, color_1], ["product_bought", { price: "$200", name: "iPhone 6S" }, "red"]
      // $window.$crisp.push(["set", "session:event", [[["product_bought", { price: "$200", name: "iPhone 6S" }, "red"]]]]);

      sharedService.setCrispInitialized(true);
    }

    function login() {
      authService.login();
    }

    function logout() {
      usersService.logout(me.user);
    }

    function unSubscribeToUserDataUpdates() {
      if (me.user && me.user.id) {
        webSocketProxy.unsubscribe(webSocketProxy.type.userData, me.user.id);
      }
    }

    function subscribeToUserDataUpdates() {
      if (me.user && me.user.id) {
        webSocketProxy.subscribe(webSocketProxy.type.userData, me.user.id, data => {
          if (data) {
            me.user = data;
            sharedService.setUser(me.user);
            me.credit = getCredit(me.user)
            sharedService.setCredit(me.credit);
          }
        });
      }
    }

    function canHideHeader() {
      return statesWithoutHeader.indexOf(stateService.getCurrentName()) !== -1 || $rootScope.isStickyActive;
    }

    function canHideFooter() {
      return statesWithoutFooter.indexOf(stateService.getCurrentName()) !== -1;
    }

    function isPageDashboard() {
      return stateService.getCurrentName() === 'app.dashboard';
    }

    function isPageUpload() {
      return stateService.getCurrentName() === 'app.projectUpload';
    }

    function isLandinPage() {
      return stateService.getCurrentName() === 'LandingPageComponent';
    }

    function getCredit(user) {
      let credit = 0;
      if (user && user.team) {
        credit = moment.duration(user.team.credit + user.team.subscriptionCredit, 'minutes')._data;
      } else if (user) {
        credit = moment.duration(user.credit + user.subscriptionCredit, 'minutes')._data;
      }
      return credit;
    }

    function buildMenuItemList() {
      if (!_.isEmpty(me.user)) {
        let list = [{
          name: 'profile',
          displayName: 'My Profile',
          classAttribute: 'profile-link',
          iconClass: 'fa fa-cog',
          callback() {
            stateService.goTo('app.accountSettings', {
              '#': 'profile'
            });
          }
        },
        {
          name: 'upgrade/subscribe',
          displayName: me.user.plan.interval ? 'Upgrade' : 'Subscribe',
          callback() {
            subscribeToPlan(me.user.plan)
          }
        },
        {
          name: 'transactionHistory',
          displayName: 'Transaction History',
          callback() {
            stateService.goTo('app.accountSettings', {
              '#': 'transactionHistory'
            });
          }
        }
        ];
        if (authService.isAdmin(me.user)) {
          list = addAdminItems(list);
        }
        if (authService.isSuperAdmin(me.user)) {
          list = addSuperAdminItems(list);
        }
        me.menuItemList = list;
      } else {
        me.menuItemList = [];
      }
    }

    function addAdminItems(menuItemList) {
      const adminItemList = [{
        name: 'latestTransactions',
        displayName: 'Latest Transactions',
        callback() {
          stateService.goTo('app.admin.latestTransaction');
        }
      },
      {
        name: 'blackwhiteList',
        displayName: 'BlackList / WhiteList',
        callback() {
          stateService.goTo('app.admin.blacklistWhitelist');
        }
      },
      {
        name: 'userLookUp',
        displayName: 'User Look-Up',
        callback() {
          stateService.goTo('app.admin.userManagementList');
        }
      },
      {
        name: 'plans',
        displayName: 'Plans',
        callback() {
          stateService.goTo('app.admin.plans');
        }
      },
      {
        name: 'defaultConfig',
        displayName: 'Default Config',
        callback() {
          stateService.goTo('app.admin.defaultConfig');
        }
      },
      {
        name: 'team',
        displayName: 'Team',
        callback() {
          stateService.goTo('app.admin.teamManagementList');
        }
      },
      {
        name: 'metrics',
        displayName: 'Metrics',
        callback() {
          stateService.goTo('app.admin.metrics');
        }
      },
      {
        name: 'publishedProjects',
        displayName: 'Published Projects',
        callback() {
          stateService.goTo('app.admin.publishedProjects');
        }
      },
      {
        name: 'languages',
        displayName: 'Languages',
        callback() {
          stateService.goTo('app.admin.languages');
        }
      },
      ];
      return menuItemList.concat(adminItemList);
    }

    function addSuperAdminItems(menuItemList) {
      const superAdminItemList = [{
        name: 'addRemoveAdmin',
        displayName: 'Add/Remove Admin',
        callback() {
          stateService.goTo('app.admin.adminManagement');
        }
      }];
      return menuItemList.concat(superAdminItemList);
    }

    function unsubscribeFromEvents() {
      unSubscribeToUserDataUpdates();
      if (angular.isFunction(userInfoChangeListener)) {
        userInfoChangeListener();
      }
    }

    function onMenuItemClick(itemName) {
      const item = _.find(me.menuItemList, {
        name: itemName
      });
      if (item && angular.isFunction(item.callback)) {
        item.callback();
      }
    }

    function userHasCredits() {
      const credits = sharedService.getCredit();
      if (!credits) {
        return false;
      }
      return credits.days > 0 || credits.hours > 0 || credits.minutes > 0 || credits.seconds > 0;
    }

    function isTeamOwner() {
      return me.user && me.user.team ? [me.user.email, me.user.cleanEmail].indexOf(me.user.team.owner) !== -1 : false;
    }

    function goToInviteSection() {
      stateService.goTo('app.accountSettings', {
        '#': 'inviteLink'
      });
    }

    function onDestroy() {
      unsubscribeFromEvents();
    }

    function subscribeToPlan(plan) {
      const modalInstance = $uibModal.open({
        animation: true,
        backdrop: 'static',
        component: 'subscribeModal',
        resolve: {
          planInfo: () => plan
        }
      });
      modalInstance.result.then(modalResponse => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'event': 'purchase', 'pricingPlan': modalResponse.planName });
        
        notificationService.success(`Yaay! You subscribed to the ${modalResponse.planName} plan. We’re happy we will be seeing you around more often 🚀.`, true);
      });
    }

    function openAddCreditModal(plan) {
      $uibModal.open({
        animation: true,
        backdrop: 'static',
        component: 'addCreditModal',
        resolve: {
          planInfo: () => plan
        }
      });
    }
  }
})();
