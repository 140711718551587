(function () {
  'use strict';

  angular
    .module('ss.admin.pages')
    .component('detailedTransactionPageComponent', {
      controller: DetailedTransactionPageComponent,
      bindings: {},
      templateUrl: 'app/admin/pages/detailed-transaction-page/detailed-transaction-page.component.html'
    });

  DetailedTransactionPageComponent.$inject = ['SS_CONSTANT', 'stateService', '$stateParams', 'projectAdminService', 'sharedProjectsService', 'timecodeHelper', 'sharedService', '$sce'];

  function DetailedTransactionPageComponent(SS_CONSTANT, stateService, $stateParams, projectAdminService, sharedProjectsService, timecodeHelper, sharedService, $sce) {
    const me = this;
    const temp = [];
    const projectId = $stateParams.projectId;
    let duration;
    me.STATUS = SS_CONSTANT.PROJECT_STATUS;

    me.$onInit = onInit;
    me.$sce = $sce;
    me.loadOriginalProjectDetailView = loadOriginalProjectDetailView;
    me.loadProjectDetailView = loadProjectDetailView;
    me.getFrameSeparatorString = getFrameSeparatorString;
    me.userDetailedView = userDetailedView;
    me.saveProjectStatus = saveProjectStatus;
    me.goToTranscript = goToTranscript;
    me.project = {};
    me.projectStatus = [
      me.STATUS.OPEN,
      me.STATUS.TRANSCRIBED,
      me.STATUS.TRANSCRIBING,
      me.STATUS.SUBTITLED,
      me.STATUS.TRANSLATED
    ];

    function onInit() {
      me.files = [];
      temp.length = 0;
      projectAdminService.getProject(projectId).then(response => {

        console.log(`Loaded Project`);
        console.log(response);

        me.project = response;
        me.project.languageDisplayName = me.project.languageDisplayName;
        me.project.failedMediaEventLogs = response.failedMediaEventLogs.map(eventLog => {
          return {
            logData: angular.fromJson(eventLog.logData),
            timestampInUtc: eventLog.timestampInUtc,
          };
        });
        me.files = response.files;
        for (let i = 0; i < me.files.length; i++) {
          const t = me.files[i];
          t.fps = String(t.fps);

          t.mediaTimecode = timecodeHelper.getMediaTimeCode(t);
          duration = me.files[i].duration;
          t.duration_ = timecodeHelper.toHHMMSSFormat(duration);
          temp.push(t);
        }
        me.files = temp;
        me.statusSelectedIndex = me.projectStatus.findIndex(status => {
          status = status === me.STATUS.TRANSCRIBED ? me.STATUS.READY : status;
          return status === me.project.status;
        });
        me.modifyStatus = false;
        getPublicProjectLinkFromProject();
      });
    }

    function getFrameSeparatorString(media) {
      return timecodeHelper.getFrameSeparatorString(media)
    }

    function userDetailedView() {
      stateService.goTo('app.admin.userManagementItem', {
        userId: me.project.userId
      });
    }

    function loadProjectDetailView() {
      stateService.goTo('app.admin.projectManagementItem', {
        projectId: projectId
      });
    }

    function loadOriginalProjectDetailView() {
      stateService.goTo('app.admin.projectManagementItem', {
        projectId: me.project.translatedFromProjectId
      });
    }

    function getPublicProjectLinkFromProject() {
      return sharedProjectsService.getProjectPublicLink(me.project);
    }

    function saveProjectStatus() {
      const status = me.projectStatus[me.statusSelectedIndex] === me.STATUS.TRANSCRIBED ? me.STATUS.READY : me.projectStatus[me.statusSelectedIndex];
      projectAdminService.updateProjectStatus({ status }, me.project.id).then(() => onInit());
    }

    function goToTranscript() {
      sharedService.redirectToProject(me.project);
    }

  }
})();
