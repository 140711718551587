(function () {
  "use strict";

  angular
    .module("ss.client.services")
    .factory("dubbingService", dubbingService);

  dubbingService.$inject = ["dataService", "storageService"];

  function dubbingService(dataService, storageService) {
    const endPoint = "dubbing";
    const service = {
      getSupportedVoices,
    };
    return service;

    function getSupportedVoices({ languageCode }) {
      const queryParams = new URLSearchParams({ languageCode }).toString();
      const path = `/${queryParams && "?" + queryParams}`;

      return dataService.get(endPoint, path).then((voices) => {
        return voices;
      });
    }
  }
})();
