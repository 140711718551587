(function () {
  'use strict';

  angular
    .module('ss.common.services')
    .factory('dataService', dataService);

  dataService.$inject = ['$http', 'host', '$window', '$document', '$timeout', '$httpParamSerializer', 'notificationService', 'usSpinnerService'];

  function dataService($http, host, $window, $document, $timeout, $httpParamSerializer, notificationService, usSpinnerService) {
    const spinner = 'spinner-ss';
    const service = {
      get: get,
      post: post,
      put: put,
      patch: patch,
      delete: remove,
      httpParamSerializer: httpParamSerializer,
      postExport: postExport,
      throwException: throwException,
      getFailureCause: getFailureCause,
      logException: logException,
      uploadFile: uploadFile,
      startSpinning: startSpinning,
      stopSpinning: stopSpinning,
      downloadFileLocally: downloadFileLocally
    };

    return service;

    ////////////////////////

    function get(endPoint, path = '') {
      return $http.get(host + endPoint + path).then(response => response.data);
    }

    function post(endPoint, payload, path = '', config = {}) {
      if (path === '' && typeof payload === 'string') {
        return $http.post(host + endPoint + payload, config).then(response => response.data);
      }
      return $http.post(host + endPoint + path, payload, config).then(response => response.data);
    }

    function put(endPoint, payload, path = '') {
      if (path === '' && typeof payload === 'string') {
        return $http.put(host + endPoint + payload).then(response => response.data);
      }
      return $http.put(host + endPoint + path, payload).then(response => response.data);
    }

    function patch(endPoint, payload, path = '') {
      if (path === '' && typeof payload === 'string') {
        return $http.patch(host + endPoint + payload).then(response => response.data);
      }
      return $http.patch(host + endPoint + path, payload).then(response => response.data);
    }

    function remove(endPoint, path = '') {
      return $http.delete(host + endPoint + path);
    }

    function httpParamSerializer(queryParam, char) {
      return char + $httpParamSerializer(queryParam);
    }

    function postExport(endPoint, payload, path = '', config = {}) {
      return $http.post(host + endPoint + path, payload, config).then(response => response);
    }

    function throwException(reason, message = '', isMetadata = false) {
      notificationService.errorCatcher(message, isMetadata)(reason);
      throw reason;
    }

    function getFailureCause(reason) {
      const cause = _.get(reason, 'data.message', '');
      return cause ? ' ' + cause : '';
    }

    function logException(reason) {
      notificationService.log(reason);
      throw reason;
    }

    function uploadFile(url, file, config) {
      return $http.put(url, file, config);
    }

    function startSpinning() {
      usSpinnerService.spin(spinner);
    }

    function stopSpinning() {
      usSpinnerService.stop(spinner);
    }

    function downloadFileLocally(response) {
      const data = response.data;
      const contentType = response.headers('Content-type');
      const contentDisposition = response.headers('Content-disposition');
      const filename = contentDisposition.split('filename=')[1];
      const blob = new $window.Blob([data], {
        type: contentType + ';charset=utf-8'
      });

      const url = $window.URL.createObjectURL(blob);
      const a = $document[0].createElement('a');
      a.download = filename;
      a.href = url;
      a.textContent = filename;
      $document[0].body.appendChild(a);
      a.click();

      $timeout(() => {
        $document[0].body.removeChild(a);
        $window.URL.revokeObjectURL(url);
      }, 100);

      return response;
    }

  }
})();
